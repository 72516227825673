/**
 * Returns string - a number of greater than four digits will have appropriate comma separation
 * @param {number} num
 */
export function numberWithCommas(num) {
  return typeof num === 'number'
    ? num
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    : undefined
}

export const blockScroll = (bool) => {
  const html = document.getElementsByTagName('html')[0]

  if (bool) {
    html.classList.add('block-scroll')
  } else {
    html.classList.remove('block-scroll')
  }
}

/**
  @description - removes all special charactes (e.g. "$", "#", "-") and creates a shopify handle-like string
  * @param {string} ex: "$0 - $25"
  * @return {string} ex. "0--25"
*/
export const removeSpecialCharactersAndHandleize = (str) => {
  return str
    ?.toLowerCase()
    .replace(/[/\\#,+()$~%.'":*?<>{}]/gi, '')
    .replaceAll('&', '-')
    .replaceAll(' ', '-')
}

export const reactContentfulImageURLHelper = (str) => {
  return str.replace('https:', '')
}

// capitalizes string and adds a period if nedded
export const altTextHelper = (str) => {
  const trim = str.trim()
  const firstLetter = trim.charAt(0).toUpperCase()

  let replaced = trim.replace(trim.charAt(0), firstLetter)

  if (replaced.charAt(replaced.length - 1) !== '.') {
    replaced = replaced.concat('.')
  }

  return replaced
}

export const hours = [
  '12:00',
  '12:30',
  '01:00',
  '01:30',
  '02:00',
  '02:30',
  '03:00',
  '03:30',
  '04:00',
  '04:30',
  '05:00',
  '05:30',
  '06:00',
  '06:30',
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30'
]

export const buildDay = (day) => {
  if (day < 10) {
    return `0${day}`
  }
  return day
}

export const remaining = (secs) => {
  const minutes = secs / 60
  const hours = minutes / 60
  const days = hours / 24

  const secondsRemaining = Math.floor(secs % 60)
  const minutesRemaining = Math.floor(minutes % 60)
  const hoursRemaining = Math.floor(hours % 24)
  const daysRemaining = Math.floor(days)

  return [
    `${daysRemaining} days ${hoursRemaining} hours ${minutesRemaining} minutes ${secondsRemaining} seconds`,
    `total hours remaining: ${numberWithCommas(hours)}`,
    `total minutes remaining: ${numberWithCommas(minutes)}`
  ]
}

export const getDifference = (str) => {
  const now = Date.now()
  const [date, time] = str.split(' ')
  const [month, day, year] = date.split('-')

  const build = `${month}-${day}-${year} ${time}`

  const target = new Date(build.replace(/-/g, '/'))

  const difference = target - now

  return difference
}

export const countdown = (str) => {
  const difference = getDifference(str)

  const seconds = difference / 1000

  return remaining(seconds)
}

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

function getNumberWithOrdinal(n) {
  const s = ['th', 'st', 'nd', 'rd']
  const v = n % 100
  return n + (s[(v - 20) % 10] || s[v] || s[0])
}

export const createReadableDateFromContentful = (dateObj) => {
  const d = new Date(dateObj)
  const year = d.getFullYear()
  const month = months[d.getMonth()]
  const day = d.getDate()
  return `${month} ${getNumberWithOrdinal(day)}, ${year}`
}

export const truncateString = (str, num) => {
  if (str.length <= num) {
    return str
  }

  return `${str.split(' ').splice(0, num).join(' ')}...`
}

export const readingTime = (str) => {
  const wpm = 225
  const words = str.trim().split(/\s+/).length
  return Math.ceil(words / wpm)
}

const getTextNodes = (arr) => {
  return arr.filter((node) => {
    if (
      node.nodeType !== 'embedded-asset-block' ||
      node.nodeType !== 'ordered-list' ||
      node.nodeType !== 'list-item'
    ) {
      return node
    }
  })
}

export const calculateReadingTimeFromContentfulContent = (arr) => {
  const textNodes = getTextNodes(arr)

  const orderedList = arr.filter((node) => {
    return node.nodeType === 'ordered-list'
  })

  const content = textNodes.map((node) => {
    return node.content
  })

  let start = ''

  orderedList[0]?.content?.map((node) => {
    if (typeof node.content[0]?.content[0]?.value === 'string') {
      start += node.content[0].content[0].value
    }

    if (Array.isArray(node.content)) {
      const tn = node.content.filter((n) => {
        return n.nodeType !== 'embedded-asset-block'
      })
      tn.map((n) => {
        n.content.map((p) => {
          start += `${p.value} `
        })
      })
    }
  })

  content.flat().map((node) => {
    if (node.value) {
      start += `${node?.value?.trim()} `
    } else if (node.content) {
      start += `${node?.content[0]?.value?.trim()} `
    }
  })

  return readingTime(start)
}

export const createReactSelectOptions = (arr, addClearOption) => {
  const r = arr.map((item) => {
    return { value: item.value, label: item.value }
  })

  if (addClearOption) {
    r.push({ value: '', label: 'clear' })
  }
  return r
}
