const yanBrand = {
  red: '#C23B22',
  background: '#000',
  foreground: '#FFF',
  carnation: '#F1625E',
  vinRouge: '#923B76',
  cottonCandy: '#FFBCD8',
  lochmara: '#0086E0'
}

export default yanBrand
