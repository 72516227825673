import { P } from 'styles/elements'
import FullScreenHeight from '../FullScreenHeight'

const Loading = () => {
  return (
    <FullScreenHeight unsetBreakpoint="none">
      <P>loading...</P>
    </FullScreenHeight>
  )
}

export default Loading
