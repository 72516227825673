const data = {
  topNavLinks: [
    {
      title: 'home',
      to: '/',
      mobileOrder: 1
    },
    {
      title: 'code',
      to: '/code',
      mobileOrder: 2
    },

    {
      title: 'music',
      to: '/music',
      mobileOrder: 3
    },

    {
      title: 'mood',
      to: '/moodboard',
      mobileOrder: 6
    },

    {
      title: 'about',
      to: '/about',
      mobileOrder: 7
    },

    {
      title: 'young and nauseous',
      to: '/young-and-nauseous',
      mobileOrder: 4
    },
    {
      title: 'notes',
      to: '/notes',
      mobileOrder: 5
    },

    {
      title: 'credits',
      to: '/credits',
      mobileOrder: 8
    }
  ],
  bottomNavLinks: [
    {
      title: 'github',
      to: 'https://github.com/danhemerlein'
    },
    {
      title: 'working not working',
      to: 'https://workingnotworking.com/58170-dan'
    },
    {
      title: 'are.na',
      to: 'https://www.are.na/dan-hemerlein'
    }
  ],
  siteThemes: [
    {
      key: 'light',
      title: 'light',
      for: 'light-mode'
    },
    {
      key: 'dim',
      title: 'dim',
      for: 'dim-mode'
    },
    {
      key: 'dark',
      title: 'dark',
      for: 'dark-mode'
    }
  ]
}
export default data
