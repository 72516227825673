const yanThemeArray = [
  '#C23B22',
  '#000',
  '#FFF',
  '#F1625E',
  '#923B76',
  '#FFBCD8',
  '#394C72',
  '#0086E0',
  '#A67775',
  '#FF85A2',
  '#EDE9D0',
  '#973FD0',
  '#BEA6A0'
]

export default yanThemeArray
