import { string } from 'prop-types'

const NotFoundIcon = ({ className }) => {
  const css = `
    .not-found-st0{fill:none;}
    .not-found-st1{fill:#202020;}
  `

  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 641 519"
      className={className}
    >
      <style>{css}</style>
      <g>
        <path
          className="not-found-st0"
          d="M414.1,89.3c-1.3,1.5-2.3,4.6-5.5,3.8c-1.3-0.3-1.8-0.5-3.5-0.4c-0.2-0.3,0.3,0.2,0,0c1.2-0.3,2.5,0.3,3.5,0.4
        C412.3,93.5,412.6,91,414.1,89.3c0.7-0.8,0.2-1.2,0.5-1.3c-0.3,0-0.6-0.9-0.9-1.2c0,0.6,0.4,0.6,0.9,1.2
        C414.9,88.1,414.2,88.9,414.1,89.3z"
        />
        <path
          className="not-found-st0"
          d="M402.5,111.5c-0.2,0.3-1,0.7-1.1,1.2C401.8,112,402.1,111.5,402.5,111.5z"
        />
        <path
          className="not-found-st0"
          d="M419.7,371.8c-2.6,1.8-5.6,1-9.4,0.7c4.5-5.7,8.4-10.6,12.3-15.6C423.3,362.5,425.3,368,419.7,371.8z"
        />
        <path
          className="not-found-st0"
          d="M294.3,446.1c3.8-3.5,4.3-6.2,5.1-9.2c0.5-2.8,0.2-5.3-2.2-9c-2.6-1.9-3.5-4.8-6.8-5.7
        c-2.6-1-7.6-5.6-10.5-6.3c-4.7-0.8-6.3-4.9-10.8-4.8c-2.6-2.4-7.3-2.9-11.5-2.4c-3.7,0.9-8.3-0.3-12.7,1.7c-5.2,2-9.6,5.7-12.7,6.9
        c-4.4,3.5-7.4,6.9-6.8,11.4c0.5,3.7,0.3,9.1,2.2,12.4c1.1,4.9,5.1,5.9,8.8,8.8c2.7,4.9,10,4.5,12.7,6.2c3.7,1.9,8.9,2.9,13.3,2
        c3.4-0.1,5.5-1.8,9.3-0.4c4.2-2,10.4-1.1,12.3-3.5C286.1,452.5,291.6,451,294.3,446.1z"
        />
        <path
          className="not-found-st0"
          d="M160.5,351.5c0-0.7-0.5-1-1.3-0.7c-4.1,0.9-6.4-1-10-1.2c-3.8,0.9-6.4,0.5-9.1,0.8c-3.4,0.6-5.9,1.1-8.4,3.9
        c-3.4,1.2-5,4.7-7,7.4c-4.2,4.1-1.3,10.8,4.2,11.2c1.7,1.1,8.4,1.3,11.3,1.8c4.4-0.5,6.4-0.4,11.2-2.2c2.7-0.5,7.3-2.3,10.2-5
        c4-4,6.5-6.5,6.3-8.3C169.4,356.8,165,353.7,160.5,351.5z"
        />
        <path
          className="not-found-st0"
          d="M430.4,321.7c-0.9,5.6-3,10.9-3.8,16.4c-0.5,1.5-1.8,1.6-2.9,2.4c-2.8,2-5.3,3.9-7.7,6.2
        c-2.2,2.3-4,4.7-5.4,8.3c-2.1,5.2-6.1,8.1-11.8,8.6c-5.3,0.5-10.5,1.8-15.9,1.4c-4.6-0.4-5.8-2.3-3.7-6.6c1.2-2.4,2.9-4.4,4.8-6.3
        c3.5-3.4,6.7-7.5,9.7-12c3.8-5.8,4.3-11.3,4-17.6c-0.5-6.1-2.5-12.3-5.6-17.8c-3-5.9-5.9-11.4-8.8-17.3c-3-5.6-6.2-11.1-8.9-17.2
        c-1.6-2.7-3-5.6-4.3-8.8c-1.5-3.1-2.6-6-4.3-8.5c-2.6-5.1-4.7-9.8-6-15.1c-1.4-5.6-3.1-10.2-2.8-16.2c-0.2-4.6,1.1-8.1,2.6-11.4
        c2-3.1,4.4-5.2,7.6-6.8c3.2-1,6.3-1.9,9.9-1.8c3.4,0.2,6.7,0.7,9.6,2.6c3.4,1.7,5.4,3.9,7.5,6.6c2,2.6,3.6,5.2,4.8,8.6
        c1.9,4.1,3.5,7.9,4.9,12.3c1.6,4.1,3.2,8.1,4.7,12.2c1.7,3.8,2.5,8.6,4.3,12.3c1.6,4.1,2.8,8.5,3.8,12.7c1.4,3.8,1.9,8.4,3.3,12.7
        c0.6,4.5,1.8,8.2,2.7,12.9c0.7,3.9,2.6,7.8,3.9,12.1c1.2,4.2,3.1,8.3,3.9,12.1C431.2,319.7,430.6,320.4,430.4,321.7z"
        />
        <path
          className="not-found-st0"
          d="M454.4,291c-3.1,4.2-6,8.8-9.5,12.9c-3.7,4.3-6.9,8.3-10.2,12.6c-1.4-3.8-2.4-7.8-3.2-11.5
        c-0.5-4-1.7-8.1-2.4-11.6c-0.9-3.7,1.8-2.6,3.2-2.3c3.8,0.2,7.4,0.1,11-0.1C446.7,291,450.5,290.7,454.4,291z"
        />
        <path
          className="not-found-st0"
          d="M545.7,293.4c-4-5.7-8.1-10.6-13.1-15.3c-4.7-4.5-10.2-8.8-15.5-13c-2.3-2.2-3.5-2.1-5.5,0.5
        c-4.1,5-8.1,10.1-12.2,14.8c-4.3,4.8-8.8,9.5-13.1,14c-2.6,2.6-3.4,4.9-3.8,8c-0.8,4.4-1.7,8.6-2.8,12.5c-1.1,4.2-2.5,8.2-3.6,12.1
        c-0.5,1.9-1.3,3.8-3.3,2.9c-2.5-1.2-1.1-3.1-0.5-4.6c1.4-4.2,3.4-8.6,4.6-13.5c1.6-4.5,2.1-9.2,2.3-13.9c-0.2-5.6,2.7-9.1,6-12.4
        c4.7-4.2,9.1-8.6,12.9-13.2c3.9-4.3,7.9-9.3,12.1-13.9c3.1-3.6,6.2-7.3,9.5-11.1c3.2-3.9,6.6-7.5,10-10.8c2.4-2.2,4.1-5.2,6.3-7.7
        c2.2-2.3,3.7-5,5.7-8.1c1.1-1.3,0.9-2.5,0.5-3.8c-0.6-5-1.1-9.5-2.7-14.2c-1-4.6-2.5-8.6-5.1-13.4c-1.4-2.1-2.3-5-3.8-7.6
        c-1-2.4-3-5.3-4.3-7.7c-1.4-2.5-3-5.3-4.4-7.6c-1.3-2.2-2.8-4.9-4.3-7.3c-1.1-2.4-2.8-5.1-4.4-7.3c-1.4-2.5-3.1-4.9-4.9-7.2
        c-2.3-3.1-4.7-6.5-7.4-9.2c-2.7-2.7-5.6-5.6-8.4-7.8c-2.6-2.2-6.1-4.4-9.7-6.3c-3.2-1.8-7.4-3.3-11-4.6c-4.3-1.3-8.1-3-12.5-4.9
        c-6.4-3.1-10.1-6.1-11.5-10c-1.6-4.2-1.4-8-1-14.5c0.1-0.4,0.3-0.9,0.4-2c1.5-4.8,1.4-9.9,1.3-14.9c-0.5-4.6-1.4-9.8-3.3-14.4
        c-1.3-3.6-3.7-6.8-6-9.5c-2.4-2.4-5.9-4.6-9.6-6.3c-4.5-2.1-9.4-3.2-14.1-3.5c-5-0.5-9.5,0.1-14,1c-6.5,1.8-11.8,4.4-16,8.8
        c-4,4.4-6.7,10-6.9,17.2c-0.3,2.9,0.1,5.9,0.6,9.2c0.4,2.9,1.2,6.3,1.4,9.1c0.2,1.8,0.6,2.8,1,3.8c0.1,0.8,0.7,2,1.5,3.4
        c0.6,0.7,3.4-0.6,3.4-1c0.3-0.2,0.7-0.4,0.6-0.5c2.7-0.3,5.3,0.8,6.4,4.2c0.6,3-0.4,5.9-2.7,7c-4.7,2.4-2.9,5.6-1,8.1
        c1.8,2.6,4.3,5.6,6,8.4c1.8,2.1,4.7,4.5,8.5,6.1c1.5,0.2,4.3,2.3,2,3.9c-2.7,2.5-6,6.3-9.6,8.5c-3.7,1.9-7.2,4.2-10.2,6.8
        c-2.9,2.4-6.1,5.1-8.4,8.2c-2.4,3-4.9,6.3-7,9.6c-1.8,3.6-4.2,6.7-5.7,10.9c-1.4,3-2.8,6.3-4.1,9.5c-1.4,3.3-2.5,6.6-3.6,9.6
        c-1.1,3.2-2.4,6.4-3.8,9.6c-1.4,3.1-2.8,6.7-4.1,9.4c-1.8,2.8-2.3,5.9-2.8,9c0,3.1-0.8,6.5-0.1,9.5c0.5,5.4,3.5,9.9,10.1,12.6
        c-1-2.7-1-6.3-2-8.8c-1-2.4-0.5-5.6-0.5-8.5c0.1-4.3,0.5-8,1.6-11c1.2-2.9,2.7-5.3,4.8-7.5c2.3-2,4.7-3.5,7.7-4.8
        c2.9-1.1,6.3-1.4,10.1-2.5c3.3-0.3,7.2,2.4,9.7,1.3c0.3-0.3,0.2-0.3,0.7-0.4c3.3-5.3,1.8-11.3,6.1-15.5c-0.2,3.8-2,6.8-0.2,11.4
        c0.4-0.1,0.5,0.3,0.2,0.8c0,0.8-0.1,1.2,0.4,1.6c2.1,7.8,3.4,8.8,11.4,9.4c4.3,0,8.7,0.6,12.9,1.2c3.9,1.2,7.8,1.9,12.1,4.5
        c2.5,1.5,4.7,3.6,6.7,6c2.1,2.3,3.5,4.8,4.9,7c1.5,2.4,2.5,5.2,3.3,8.1c0.9,2.7,1.6,5.8,2.1,8.6c0.5,1.8,0,4-1.2,5.8
        c-0.6,1.8-2.1,3.3-4.7,3.2c-2.5,0.1-3.4-1.1-4.6-3.2c-1.5-3.4-2.8-6.6-3.7-9.8c-0.4-2.2-1.6-5.2-3.8-6.7c-0.8,3.8,0.6,6.1,1.2,9.2
        c0.6,3.3,0.7,6.4,0.5,8.8c-0.5,3-1.5,5.4-4.2,7.4c-4,3.6-5.2,6.5-5,9.9c-0.4-0.1,0.2,1-0.6,0.9c-0.9,2.4-0.8,5.7,0,8.4
        c1,3.4,2.4,7.5,4.8,10.3c0.3,0.6,0.5,1.1,1.3,1.3c3.1,1.4,6.4,0.6,10.3,1.2c4.7,1.3,11.4,0.1,16.4,2.8c1.7-2.8,3.4-5.2,5.1-8.1
        c1.7-2.8,2.8-5.6,4.5-8.4c1.8-3.5,3.7-6.4,5.5-9.7c0.7-0.7,1.2-2.2,2-3.1c2.8-2.9,5.4-6.3,7.2-9.7c2.2-3.6,4.1-7,6.1-10.8
        c2-4.2,3.9-7.1,6-10.7c2.5-3.5,4.4-6.8,7.1-9.8c0.6-0.3,0.9-0.9,0.9-0.7c-1.6-0.8-2.8-2.2-3.1-3.2c-2.1-3.3-3-6.8-4.4-11
        c-1-1.3-1.2-2.1-1.8-3.7c-1.5-3.4-2.5-6.3-3.4-9.8c-1.3-3.5-1.7-6.9-2.2-9.9c-0.2-0.5,0.7-1.4,1-1.3c1,4.7,2.2,7.8,4.4,11.2
        c1.8,3.6,4.1,7,6.5,9.6c0.6,1.4,2.1,2.4,3,3.7c1.7,3.2,3.9,5.5,6,7.7c0.2,0.6-0.4,2.8-1.9,4.4c-0.1,0.4-0.7,0.6-0.8,0.8
        c1.3-0.3,3-1.4,4.7-0.8c-1,0.9-1.7,2.5-2.7,3.3c-0.8,1.7-1.8,2.4-2.7,3.4c-2.7,3.2-5,6.9-7.7,10.2c-2.6,3.2-5,6.8-7.1,10.4
        c-2.2,3.4-4.5,7-6.5,10.8c-2,3.5-4.2,7.7-6.1,11.1c-0.8,0.8-1.5,1.8-1.9,2.8c-4.6,6.7-8.9,13.7-13.1,20.2
        c-4.3,6.8-9.2,13.4-14.6,19.2c-2.5,2.6-4.6,6-6.5,8.9c-1.9,3-3,6.6-4.3,10.2c-1.5,4.6-2.2,10-2.7,14.9c-0.6,5.3-1,10.2-3.1,14.8
        c2.2-2.2,3.7-4.5,5.2-7c1.3-2.6,2.7-5.2,3.7-7.8c1.4-4.4,3.2-9.2,9.2-5.4c0.5,0.2,2,0.1,2.3-0.4c1.8-1,3.5-1.2,5.2,0.2
        c1.9,1.4,4.4,1.3,6.6,0.4c2.1-0.7,4.4-1,6.4-0.5c3.5,0.3,6.4-0.3,9.1-2.8c2.3-2.4,3.7-5.3,3.8-9c-0.2-1.3-1.1-3.2,1.1-3.6
        c1.7,0,3.2,1.5,3.3,3.2c0.6,1.3,0.5,3.4,0.2,4.4c-1.1,2.6,0.4,3,2.5,3.6c6.1,1.6,12.1,2.4,18.7,3c3.1,0.3,6.3,0.4,9.4,0.4
        c3-0.1,6.3,0.6,9.5-0.4c3.6-0.5,6.5-1.9,9.5-2.7c2.6-1.4,5.6-2.7,7.6-4.8c2.3-2.8,4.5-4.4,5.3-7.4c1.8-3.1,1.8-4.9,2.4-8.2
        c0.4-3,0.2-6-0.5-9C548.9,299.3,547.6,296.3,545.7,293.4z M402.7,85.4c2.2-1.6,5.2-2.5,7.7-1.2c0.1-0.2,0.6,0.8,0.6,0.3
        c-0.1-0.1,0,0.2,0,0c-0.1,0,0.1,0.1,0.3,0.2c0.6-0.4,0.2,0.2,0,0c0.5-0.1,0.6,0.1,0.3,0.2c-0.3,0,0.2-0.1,0,0
        c0.3-0.5,0,0.5,0.1,0.1c-0.4-0.3,0.3,0.5,0.2,0.2c0-0.4,0.3,0.5,0.6,0.5c-0.7-0.1,0.3-0.2,0.4,0.4c0.1,0.3-0.2,0.5,0.4,0.4
        c0.4,0.4-0.2,0.3,0.2,0.2c0,0.3,0.1,0.6,0.2,0.2c0.2,0.4,0.7,0.9,0.9,1.2c-0.2,0.6-0.4,0.9-0.5,1.3c-0.9,2.4-1.7,4.2-5.5,3.8
        c-1.1-0.1-2-0.3-3.5-0.4c-0.5-0.5-0.2-1.2-0.8-1.3c-1-1.3-1.3-2.6-2.8-4.3C401.8,86.5,402.1,85.6,402.7,85.4z M395.8,106.2
        c-0.4-1.6,0.9-2.4,2.5-2.2c2,1,5.3-0.1,5.6,2.7c0.2,1.6-1.6,1.7-2.1,2.1C398.3,109.1,395.8,108.9,395.8,106.2z M404.2,115.3
        c-1,0.1-2.6-0.1-2.8-1.7c0.2-0.6-0.4-0.7,0-0.9c0.3-0.5,0.4-0.9,1.1-1.2c0.3,0.2,0.8,0,0.9-0.2c3.4-0.3,7.1-1.5,11.9-0.2
        C411.8,115.1,407.3,113.7,404.2,115.3z"
        />
        <path
          className="not-found-st0"
          d="M442.8,243.5c-2.4-4.6-2.9-10.3-5.3-14.7c-1.4-2.3-2.2-4.1-4.5-5.1c-4.7-2.6-8.9-0.3-8.6,5.1
        c0.3,4,0.8,8.6,1.6,12.9c1,3.1,0.3,5.3-1.5,7.9c-1.8,2.4-4,4.4-6.9,6.1c-0.7-4-1.6-8.5-3-12.2c-1.4-4.1-2.8-7.8-4-11.9
        c-1.5-4-3-7.8-4.8-11.5c-1.9-4-3.9-7.5-6-11.3c4.5,0.1,9.4,1.1,13.1,1.8c4,1,8,1.9,11.4,3.5c3.7,1.6,6.5,3.2,9.1,5.4
        c2.7,2.2,4.5,4.4,6.3,6.7c1.4,2.2,2.8,4.9,3.3,8C443.4,237.4,443.7,240.4,442.8,243.5z"
        />
        <path
          className="not-found-st0"
          d="M390.8,330.6c-1.3,2.8-8,5.5-11.5,3.9c-3.9-1.9-2.3-4.9-1.9-7.6c0.8-4.3,2.3-8.2,4.1-11.9
        c1.7-4,4.1-7.5,6.9-11.2c2,4.4,3.1,9.1,3.8,13.4C392.7,321.4,392.5,325.8,390.8,330.6z"
        />
        <g>
          <path
            className="not-found-st1"
            d="M554.6,302.7c-0.5-3.5-1.4-6.9-3.1-10c-1.5-2.4-3-4.6-4.9-7c-2-1.8-3.7-4.3-5.9-6.2c-3.4-3.3-6.4-6.5-10-9.8
          c-3.4-3.4-6.7-7-9.5-10.1c-2.2-2.5-1.6-3.1,0.1-5.1c3.3-4.5,8-8.9,11.8-13.1c3.9-4.3,8.1-9,10.9-14c2.1-2.9,4.5-5.5,4-8.9
          c-0.8-3-1.1-6.9-1.8-10.2c-0.6-3.4-1.5-6.7-2.6-10c-1.3-3.1-2-6.6-3.5-9.7c-1.4-3.2-2.9-6.2-4.5-9.2c-1.7-3.1-3.2-5.9-4.8-8.4
          c-1.5-3-3-5.4-4.9-8.1c-2.1-3-3.5-5.3-5.3-8.1c-1.5-2.6-3.2-5.2-5-8c-1.7-3-3.3-5.2-5.5-7.8c-1.9-2.6-4.2-4.4-6-7
          c-2.2-2.2-4.3-4.1-6.7-6.1c-2.3-1.9-4.9-3.6-7.4-5.4c-2.4-1.8-5.2-3.4-8.2-4.7c-3-1.2-5.7-2.3-8.8-3.3c-3.7-0.8-6.6-2.9-10.1-4.3
          c-4.9-2.4-8.8-4.6-10.5-8.3c-1.8-3.6-2-7.3-0.7-13c1-4.8,1.2-9.4,0.9-14.1c-0.1-4.5-1.1-9.6-2-14c-1.5-6.1-4.2-10.8-7.8-14.6
          c-2.6-2.2-5.8-4.7-8.6-6.2c-3.3-1.7-6.3-2.9-10.1-3.8c0,0,0.2,0.1,0,0.1c-1.2-0.1-1.1-0.1-2.4-0.4c-0.7,0.1-1.7-0.6-2.2-0.2
          c-1.7-0.4-2.8-0.2-4.1-0.2c-0.2,0.1-5.3,0.1-5.6,0c-0.7-0.2-0.8,0.3-1.4,0.3c-1.4,0.4-1.5,0.6-0.1,0c0.8-0.1,0.8-0.4,1.3-0.3
          c-1-0.1-1.9-0.3-3.5-0.1c-2.2,0.5-4.8,0.7-6.9,1.4c-6.7,1.5-13,5.2-17.6,10.5c-4.4,4.9-7.4,11.3-7,18.7c0.3,4.7,0.1,8.5,0.8,12.3
          c0.4,4.2,1.2,8.1,2.4,12c0.7,1,1.9,2.2,2.7,3c-0.7-2.1-0.5-1.6,0.1,0.1c0,0.2,0.3,0.2,0.1,0.1c0.5,1.1,1.4,1.7,1.6,2.4
          c0.5,1.5,1,2.4,1,4.5c0.2,0.4,0.5,1.8,1.5,3.7c0.4,0.2,0.3,0.7,0.2,0.8c0.4,1.1,0.8,1.4,0.9,2.5c0.6,0.9,1.3,3.2,2.2,4.6
          c0.5,1.5,1.7,2.8,2.5,4.2c0.8,1.4,2.2,3.3,3.6,4.1c0.1,0.1,0.4-0.7,0.1,0.1c1.7,1.7,8.3,5.9,8,7.2c-0.2,1.9-2.7,1.8-4.1,2.4
          c-3.7,1.2-6.6,2.4-9.5,4.1c-3.1,1.6-5.9,3.6-8.1,5.9c-2.5,2.4-5,4.7-7.2,7.3c-2.3,2.5-4.3,4.8-6.1,8.1c-2.3,3.4-3.9,7.5-6,11.7
          c-1.9,3.9-3.3,7.8-5.4,12c-1.4,2.7-2.9,5.5-4.3,8.5c-1.5,2.9-2.4,6-3.5,8.7c-1.4,2.6-2,5.9-3.2,8.8c-1.3,2.9-1.9,6.1-2.8,9.2
          c-1,3.6-1.8,7.1-1.8,10.2c-0.1,2.9,0.7,6.1,1.2,9.1c0.8,2.7,2.7,5,4.5,7.5c1.8,2.2,4.5,4.4,8,6.2c5.8,3.1,7.7,7.5,10.1,12.6
          c1,2.3,2.1,4.6,3.1,6.7c2.6,4.5,4.8,9.6,7.6,14.2c2.5,5,5.2,9.5,7.7,14.2c2.4,4.4,3,8.1-0.8,12.3c-1.7,2.1-3.6,4.4-4.9,7.1
          c-1.5,2.5-2.5,5.6-3.6,8c-2.9,7.1-3.5,12.1-1.7,15.5c1.9,3.4,6.2,5.5,13.7,6.2c0.1,1.5-1.5,2.1-2.3,3c-2.6,3-6,5.4-7.7,9.1
          c-3.4,7.5-3.2,16.3,8,15.8c4.8-0.2,9.6-0.8,14.4-1.2c4.3-0.3,8-1.4,11-3.5s5.4-5.1,7-9.1c1.7-4.3,4.6-7.5,9.5-10.3
          c-0.5,0.1,0,0.1-0.3-0.1c0.3-0.2,0.3,0.1,0.5-0.3c0,0.3-0.1,0.4-0.2,0.6c0,0.2,0.1,0.2,0.2,0.2c-1.2,3.7-3.1,6.3-5.1,9.1
          c-3.2,4.6-6.9,8.9-10.4,13.3c-3.5,4.4-7,8.9-10,13.7c-0.6,1-2.4,1.8-0.9,3.3c2.3,2.5,13,0.9,15.1-2c4.9-6.8,9-14.7,15.4-19.9
          c3.2-2.6,5.7-5.6,7.7-8.8c2.2-3.3,3.3-6.9,5.2-10.2c0-0.1,0.3-0.8,0.5-0.9c0.2-1.9,2-3.5,3.5-2.9c0.9,0.3,0.9,0.7,2.1,0.5
          c1.1-0.2,2.4-0.3,3.3-0.5c4.5-0.6,8.7,1.2,12.9-0.1c1.7-0.5,3.4-1,5.3-0.9c0.7,0.2,1.2,0,1.7,0.1c0.2,0.2-0.2,0.1,0,0
          c-0.1-0.2,0-0.2,0.1,0c-0.1,0.1-0.1-0.1,0.1,0c0.1,0,0.4-0.2,0.6,0c0,0.1,0.4-0.4,0.4-0.1c-0.1-0.1-0.1,0.2-0.2,0
          c0.4,0,1.3,0.1,1.6-0.3c0.1,0.1,0.4-0.2,0,0c0.1,0,0-0.1,0.3-0.1c0,0.2,0.3-0.1,0.3-0.1c0.2,0,0.5-0.3,0.7-0.3
          c0,0.1,0.3-0.1,0.7-0.4c0.2-0.1,0.2,0.1,0.2-0.1c0.3-0.2,0.1,0,0.5-0.3c0.3,0.2,0.7-0.5,0.9-0.6c0.7,0,1.2-0.7,1.6-0.9
          c-0.2,0.3,0,0.1,0.1,0c0.6-0.4,0.8-0.4,1.6-0.7c0.3,0.2,0,0,0.1,0c0.5,0,0.6-0.2,0.8-0.2c0.8-0.4,2-0.3,2.4,0.2
          c0.2,0.2,0.4-0.2,0.3,0.1c1.3-0.4,3.7,0.2,5.2,0.4c1.9,0.3,4.4,0.9,6.3,1.4c0.5,0.2,0.6,0.2,1,0.1c2.8,0.4,5,0.5,7.5,0.8
          c2.9,0.1,5.7,0.3,9.1,0.6c5.7,0.1,10.2-0.4,16.1-0.9c1.3,0.2,2.6-1.1,3.4-1c2.2-0.6,4.4-2,6.3-3.1c2.5-1.1,5.8-3.7,7.3-6.2
          c2-2.3,4.1-4.9,4.7-7.7c1-2.5,2.8-6,2.8-9C555,308.8,555.1,305.8,554.6,302.7z M419.7,371.8c-2.6,1.8-5.6,1-9.4,0.7
          c4.5-5.7,8.4-10.6,12.3-15.6C423.3,362.5,425.3,368,419.7,371.8z M379.3,334.5c-4-1.6-2.1-4.8-1.9-7.6c0.7-4.1,2-8.2,4.1-11.9
          c1.9-3.6,4.2-7.5,6.9-11.2c1.6,4.5,3.3,9,3.8,13.4c0.7,4.4,0.6,8.9-1.4,13.4C389.5,333.5,382.7,335.8,379.3,334.5z M426.6,338.1
          c-0.2,1.9-2.1,1.7-2.9,2.4c-3,1.9-5.4,4.1-7.7,6.2c-2.4,2.4-4.4,5-5.4,8.3c-2.1,5.2-6.1,8.1-11.8,8.6c-5.3,0.5-10.5,1.8-15.9,1.4
          c-4.6-0.4-5.8-2.3-3.7-6.6c1.2-2.4,2.9-4.4,4.8-6.3c3.4-3.6,6.9-7.5,9.7-12c3.2-5.5,4.5-11.4,4-17.6c-0.5-5.7-2.5-12-5.6-17.8
          c-3-6-5.7-11.6-8.8-17.3c-3.3-5.4-6.1-11.7-8.9-17.2c-1.4-2.8-3-5.8-4.4-8.7c-1.7-2.7-2.9-5.9-4.1-8.6c-2-4.4-4.2-9.8-6-15.1
          c-1.5-5.3-2.8-10.5-2.8-16.2c-0.3-4.7,0.8-8.4,2.6-11.4c1.7-3.2,4.4-5.5,7.6-6.8c3.5-1.4,6.6-2,9.9-2c3.4,0.2,6.6,0.7,9.7,2.8
          c2.6,2,5.2,3,7.5,6.6c2.3,2.2,3.4,6,4.8,8.6c1.8,3.8,3.2,8.2,5,12.2c1.5,4.1,2.9,7.8,4.7,12.3c1.3,4.2,3,8.3,4.3,12.5
          c1.4,4.1,2.6,8.5,3.8,12.5c1.1,4.1,2.4,8.5,3.3,12.7c1.1,4.3,1.9,8.7,2.7,12.9c0.7,4,2.2,8.3,3.9,12.1c1.5,3.9,3.1,8.4,3.9,12.1
          c0.3,0.9,0.2,1.9-0.1,3C429.1,327.4,427.5,333,426.6,338.1z M426,241.7c1.1,2.8,0,5.5-1.5,7.9c-2,2.1-4.1,4.3-6.9,6.1
          c-0.6-4.2-1.6-8.2-3-12.4c-1.4-4.2-2.4-7.9-3.9-11.8c-1.6-3.9-3-7.8-5.1-11.6c-1.9-3.5-3.5-7.7-5.7-11.3c4.4,0.1,9.1,0.7,13.1,2
          c4.1,0.9,8.1,2.4,11.4,3.3c3.5,1.6,6.2,3.4,8.9,5.4c2.4,1.8,4.5,4,6.4,6.7c1.5,2.3,3,5.4,3.4,8c0.4,2.8,0.4,6.1-0.3,9.2
          c-2.8-5.4-2.5-10.2-5.3-14.7c-0.9-1.8-2.2-3.6-4.5-5.1c-4.8-2.5-8.7-1-8.6,5.1C424.8,232.6,424.8,237.7,426,241.7z M432.3,291.1
          c3.9,0,7.3,0,11-0.1c3.6-0.3,7.4-0.3,11.1,0c-3,4.5-5.9,8.9-9.5,12.9c-3.2,4.1-7,8.4-10.2,12.6c-1.3-4-2.2-7.7-3.2-11.5
          c-0.8-3.8-1.4-7.8-2.4-11.6C428.4,289.7,430.8,291.1,432.3,291.1z M525.3,334.4c-3.2,0.9-5.5,0.5-9.5,0.4c-3.2,0-6.3-0.1-9.4-0.3
          c-6.5-0.8-12.6-1.6-18.7-3c-2.1-0.6-3.4-0.9-2.5-3.6c0.2-1.1,0.3-3-0.2-4.4c-0.4-1.4-1.1-3.3-3.3-3.2c-2.4,0.5-1.1,1.9-1.1,3.6
          c-0.2,3.8-1.6,7.1-3.8,9c-2.2,2.2-5.8,3.4-9.1,2.8c-2-0.4-4.2-0.3-6.4,0.5c-2.2,1.1-4.5,1.2-6.6-0.4c-1.5-1.1-3.2-1.4-5.2-0.2
          c-0.7,0.6-1.8,0.5-2.3,0.4c-6.5-3.7-7.5,1.3-9.2,5.4c-1.1,3.1-2.2,5.3-3.7,7.8c-1.4,2.7-2.8,4.4-5.2,7c1.8-4.9,2.6-9.6,3.1-14.8
          c0.5-5,1.3-10.1,2.7-14.9c0.9-3.8,2.6-6.9,4.3-10.2c1.7-3.3,4-6.5,6.5-8.9c5.5-5.7,10.3-12.1,14.6-19.2
          c4.4-6.6,8.4-13.7,13.1-20.2c0.2-1.1,1.5-2,1.9-2.8c2.1-3.8,3.7-7.3,6-11c2.2-3.8,4.5-7.2,6.5-10.9c2.4-3.7,4.6-7.3,7.1-10.5
          c2.5-3.6,5.1-6.7,7.7-10.1c0.6-0.7,1.8-2,2.7-3.4c0.6-1.1,1.4-2.6,2.7-3.3c-1.8,0-3.1,0.5-4.7,0.8c-0.3-0.6,1.4-0.8,0.8-0.8
          c1.6-2.1,2.3-3.7,1.9-4.4c-2.2-2.6-4.2-4.2-6-7.7c-1.2-1.3-2.2-2.5-3-3.7c-2.3-3.7-5.1-6-6.5-9.6c-1.8-3.4-3.4-7.5-4.4-11.2
          c0.3-0.4-1.2,0.9-1,1.3c0.2,3.2,0.8,6.4,2.2,9.9c0.9,3.1,2.5,6.9,3.4,9.8c1,1.5,1.2,2.8,1.8,3.7c1,3.9,1.9,7.7,4.4,11
          c0.9,1.5,1.9,2,3.1,3.2c0,0.2-0.7,0.5-0.9,0.7c-2.9,3.3-4.8,6.3-7.1,9.9c-2.2,3.5-3.9,6.9-6,10.6c-1.9,3.7-3.7,6.9-5.8,10.7
          c-2,3.4-4.4,6.6-7.4,9.8c-1,1-1.2,1.9-2,3.1c-2.1,3-3.8,6.2-5.5,9.7c-1.5,3.1-2.8,5.5-4.5,8.4c-1.9,2.8-3.4,5.2-5.1,8.1
          c-5-2.6-11.4-1.3-16.4-2.8c-3.3-0.3-7.8,0.5-10.3-1.2c-0.5-0.5-0.7-0.7-1.3-1.3c-2.8-2.6-3.7-6.8-4.8-10.3c-0.6-2.4-1.3-6.1,0-8.4
          c0.5,0.1,0.4-0.9,0.6-0.9c0.2-3.6,1.6-6.7,5-9.9c2.3-1.9,4.1-4.6,4.2-7.4c1-2.1,0.4-6.3-0.5-8.8c-0.5-3.2-1.6-5.4-1.2-9.2
          c2.1,1.8,3.3,4.7,3.8,6.7c1.3,3.2,2.4,6.4,3.7,9.8c0.6,1.7,2.3,3.1,4.6,3.2c1.8-0.1,3.6-1.3,4.7-3.2c0.9-1.5,1.5-3.7,1.2-5.8
          c-0.5-2.8-1.1-5.7-2.2-8.4c-0.6-2.9-2.1-5.5-3.2-8.3c-1.4-2.3-2.4-5.3-4.6-7.2c-1.9-2.5-4.3-4-7-5.8c-4-2.4-8-3.7-12.1-4.5
          c-4.5-0.4-8.2-1.1-12.9-1.2c-8.2-0.2-9.3-1.1-11.4-9.4c-0.4-0.5-0.2-1.2-0.4-1.6c-0.6-0.2,0-0.9-0.2-0.8
          c-1.7-3.8,0.1-7.3,0.2-11.4c-4.1,3.7-3.4,10.4-6.1,15.5c-0.1,0.2-0.2,0.1-0.7,0.4c-2.3,1.2-6.5-2.2-9.7-1.3
          c-3.7,0.3-7.3,1.3-10.4,2.5c-2.7,1-5.6,2.8-7.4,4.8c-2.4,2.3-3.5,4.7-4.8,7.6c-1.1,3.1-1.7,6.9-1.6,10.9c-0.4,2.9,0.4,5.8,0.5,8.5
          c0.5,2.7,0.6,6,2,8.8c-6.3-2.8-9.3-6.6-10.1-12.6c-0.6-3.6-0.3-6.5,0.1-9.5c0.3-2.9,1.7-6.2,2.8-9c1.5-3.6,3.2-6.3,4.2-9.4
          c1.1-3.2,2.5-6.4,3.8-9.6c1.3-3.3,2.4-6.2,3.7-9.5c1.5-3.3,2.6-6.4,4-9.6c1.7-3.8,3.5-7.5,5.6-11c2-3.6,4.7-6.7,7.1-9.5
          c2.9-3,5.4-5.9,8.5-8.2c2.6-3,6.2-5,10.1-6.8c3.9-2.1,6.2-5.7,9.6-8.5c1.9-1.6-0.8-3.3-2-3.9c-3.4-1.3-6.7-3.2-8.5-6.1
          c-1.9-3-3.8-5.3-6-8.4c-2-2.3-3.8-5.8,1-8.1c2.4-0.6,3.3-3.9,2.7-7c-1-2.9-3.2-4.8-6.4-4.2c-0.4,0.3-0.6,0.5-0.6,0.5
          c-0.5,0.4-2.6,2-3.4,1c-1-1.1-1.2-2.2-1.5-3.4c-0.5-1.1-0.9-2.4-1-3.8c-0.7-3.2-1-5.9-1.4-9.1c-0.2-2.9-0.7-5.7-0.6-9.2
          c0-7.4,3.1-12.8,6.9-17.2c4-4.2,10-7.3,16-8.8c4.3-0.8,9.3-1.4,14-1c5,0.6,9.4,1.6,14.1,3.5c4.3,1.9,7,3.6,9.6,6.3
          c2.9,2.6,4.6,6,6,9.5c1.4,4.7,3,9.6,3.3,14.4c0.5,4.9,0,9.6-1.3,14.9c-0.1,0.7-0.6,2-0.4,2c-0.4,5.6-0.9,10.5,1,14.5
          c1.3,4.4,5.2,6.9,11.5,10c4.3,1.8,8.1,3.3,12.5,4.9c3.9,1.4,7.2,3.3,10.7,4.8c3.6,1.7,6.9,4,9.9,6c3.4,2.6,5.7,4.5,8.5,7.6
          c2.7,3.1,5.1,5.9,7.4,9.5c1.7,2.9,3.2,5.1,4.7,7.4c1.6,2.5,3.1,4.8,4.6,7.1c1.5,2.5,3.3,4.9,4.4,7.4c1.3,2.5,3,4.6,4.3,7.5
          c1.4,2.6,3.2,4.8,4.1,7.5c1.1,2.6,2.7,5.1,4,7.7c2.3,4.5,4.1,8.9,5.1,13.4c1.4,4.6,1.9,9.4,2.7,14.2c0.3,0.9,0.7,2.5-0.5,3.8
          c-2.3,2.6-3.8,5.3-5.7,8.1c-1.8,2.9-3.8,5.3-6.3,7.7c-3.6,3.7-6.7,7.1-10,10.8c-3.4,3.6-6.4,7.3-9.5,11.1
          c-3.9,4.3-8,9.1-12.1,13.9c-4.2,4.6-8.3,8.9-12.9,13.2c-3.3,3.4-6,7-6,12.4c0.3,4.7-0.8,9.5-2.3,13.9c-1.2,4.7-3,8.8-4.6,13.5
          c-0.7,1.4-1.9,3.6,0.5,4.6c2.5,0.9,3-1.3,3.3-2.9c1.2-4,2.4-8.1,3.6-12.1c1.4-4.2,2.1-8.1,2.8-12.5c0.5-3,1.5-5.4,3.8-8
          c4.3-4.6,9-9.4,13.1-14c4.4-5.1,8.2-9.8,12.2-14.8c2.4-2.7,3-2.5,5.5-0.5c5.5,4.4,10.8,8.3,15.5,13c4.7,4.8,9.6,9.4,13.1,15.3
          c1.9,2.9,3.2,5.9,4,8.9c0.7,3,0.9,6.1,0.5,9c-0.4,2.5-1.2,5.3-2.7,8.6c-1,3.2-3.3,5.3-5,7c-2.6,1.8-4.5,3.8-7.6,4.9
          C532,332.8,529.4,333.7,525.3,334.4z"
          />
          <path
            className="not-found-st1"
            d="M386.1,107c0.8,2.7,2.6,4.2,4,6.3c0.9,1.5,1.7,2.4,2.9,3.5c-2.4-2.6-4.6-6.1-5.8-9.2
          c-1.3-2.9-2.7-5.9-3.9-9.1c0.2,0.9,0.3,2.1,0.4,3.5C384.5,104.4,385.6,105.5,386.1,107z"
          />
          <path
            className="not-found-st1"
            d="M247.1,399.6c-4,4.2-9.5-0.5-12.4,1c-3.8,1.1-8.4,2.4-12.9,4.8c-6.6,4.6-7.1,7.4-9.2,9.9
          c-0.9,3-1.9,9.9-3.6,9.2c-2.3,3.4,0.4,5,0.2,8c0.1,1.7,1.6,4.7,3.1,7.7c2.4,2.2,3.8,7.3,5.6,7.6c1.3,1.3,3.8,4.7,6.7,6.7
          c5,1.2,4.3,3.5,7.9,5.9c0.2,1.9,6.7,4.3,8.3,4.8c3.9,1.6,5,2.6,9.9,3.1c4.5,1.7,8.8,2.7,12.4,2.2c3.1-0.4,6.3-0.8,9.3,0.5
          c3.4,1.4,6.5-0.2,8.9-0.4c2.3-0.9,5.7-2.3,8.9-2c1.8-2.4,6.9-2.3,8.9-3.8c2-1.2,4.9-4.2,8.3-5.6c3.1-2.3,4.3-2.6,6.5-7.4
          c1.1-4,2.7-6.7,3.1-9c0.4-3.8-1.6-6.7-3.1-9.2c-3-4.3-5.7-5.1-8.9-10.1c-3.4-2.9-5.6-6.5-9.8-8.3c-2.9-3.2-7.1-5.8-10.6-6.6
          c-5.1-2.3-6.1-3.5-11.8-4.6c-4.5-2.2-8.4-0.6-12.7-3.3C254.7,400.6,249.7,398.2,247.1,399.6z M294.3,446.1
          c-4.7,3.3-6.9,6.7-10.3,7.9c-4.6,1.4-5.8,2-12.3,3.5c-2,0.4-7.3,0.8-9.3,0.4c-6.3-0.1-8.1-1.7-13.3-2c-5.2-0.4-8.4-4.5-12.7-6.2
          c-3.7-2.1-7-4.3-8.8-8.8c-1.6-0.8-2.3-8-2.2-12.4c0.9-4.1,2-7.6,6.8-11.4c2.3-3.2,7.2-3,12.7-6.9c5.5-1.4,10,0.9,13.1-1.6
          c5.2,2.5,7,1.2,11.1,2.3c3.5,2.5,6.1,3.4,10.8,5.1c4.4,2.7,8.1,2.8,10.5,6.1c2.3,2.8,4.9,4,6.8,5.7c1.7,3.1,3.2,5.4,2.2,9
          C297.8,438.5,298.7,443.2,294.3,446.1z"
          />
          <path
            className="not-found-st1"
            d="M396.2,400.6c-1.5,2.2-3.2,4.4-5.4,5.8c-3.7,2.4-7.2,3.8-11.4,5.8c-3.5,1.7-6.9,3.1-11.6,3.7
          c-3.1,0.4-8,2.3-12.3,0.9c-4.2-0.1-8.1-0.4-12.7-1.9c-4.8-3-5.5-2.8-9.5-3.6c-2.6-1.8-4.6-4.6-8-6c-1.2-1.5-3.7-3.2-4-7.5
          c-0.5-2.1-1-5.6,2.2-8.6c2.2-2.3,3.8-5,8.9-8.6c3.5-1.3,5.1-3.3,8.8-4.3c2.5-1.5,5.1-1.5,9.8-1.9c0.9-1.4,5.3-1.8,9.9-1.5
          c3.4-0.6,6.8-1.4,10.1-2.7c-3.9-1.1-7.6-1.7-11.2-1.9s-6.2-0.2-10.7,0.4c-2.9,2.2-7.3,0.6-8.8,1.6c-2.8,0.9-5.7,2.4-8.5,2.6
          c-1.7,1.1-3.4,2.9-7.6,3.8c-1.6,1.6-4.1,3.6-7.5,5.7c-2.9,1.9-5.6,6.5-5.5,8.4c-2.1,3.3-0.6,6.6,0.6,10.1c1.2,3,2.7,5.5,4.9,8.5
          c1,2.1,4.8,5.4,7.3,6.8c4.1,4.4,6.8,6.2,13.8,6.2c4.5,2.1,9.5,0.2,15,2.3c3.8,1.4,7.9,3,10.6,0.7c2.7-0.6,6.6-0.4,10.2-1.5
          c2.4-0.4,6.5-2.5,9.9-3.1c3.7-1.7,6.3-2.8,9.2-4.9c2.7-1.8,5.4-3.6,7.9-5.6s4.5-4.5,5.8-7.7c0.4-0.8,1.5-1.4,0.5-2.6
          C404.2,397.1,398.3,397.4,396.2,400.6z"
          />
          <path
            className="not-found-st1"
            d="M181,359.8c-2.2-5.1-5.6-6.9-8.1-8.8c-3.8-4.1-6.4-4.3-13.1-6c-3.6-0.4-8.8-1.5-14.3-1.5
          c-3.9,0.2-3.8,0.6-7,0.1c-3.3,1.4-9.7,0.5-14.6,3.8c-4.3,3-8,3.2-11.5,9.8c-0.7,3.1-4,5.8-2.3,8.9c-0.9,4,1.4,7.4,4.1,8.6
          c3,4.1,7.9,2.9,11.7,5.8c3.1,1.6,5.6,0.2,9.4,1.7c2.7-0.8,7.1,0.7,9.3,0.5c3.4-1.5,4.9-1.5,8.7-1.1c1.7,0.1,6.7-3.1,8.8-2.5
          c3.2-0.9,5.2-1.6,8.4-3.8c3.2-0.3,5.8-3.7,7.9-5.6C182.6,365.7,182.6,363.2,181,359.8z M161.7,367.6c-2.5,2.9-6.5,3.8-10.2,5
          c-2.7,1.8-7.8,2.3-11.2,2.2c-2.4-1.6-7.3-0.8-11.3-1.8c-5.6-1.6-7-6.2-4.2-11.2c2-3.8,5.4-5.1,7.2-7.4c2.2-3.3,5.7-2.6,8.1-4
          c2.8-0.1,7.2-1.1,9.2-0.9c4-0.4,7.1,0.3,9.8,1.2c0.9-1.4,0.9-0.3,1.3,0.7c5,2,7.1,5.3,7.5,7.8C168,361.2,165.5,366.5,161.7,367.6z
          "
          />
          <path
            className="not-found-st1"
            d="M226.2,383.4c3.1-2.8,2.6-3.1-0.1-4.9c-5.6-1.9-11.5-5.4-14.9-0.5c-3.7,3.9-4.7,5-9.7,5
          c-2.5,3-4.8,3.2-8.2,5.3c-4.3,1.7-4.1,4.3-8.6,4.9c-1.8,2.9-4.8,6.3-8.6,4.9c-3.3,1.1-7.1,4.3-8.5,4.5c-3,1.1-3.7,2.6-5.8,3
          c-3.2,1.4-2.7,2.2-0.8,3.1c2.6,2.6,4.1,4.5,8.4,4c3.1,0.3,3.4-4.2,5-2.1c2.5-2.4,5.6-4.3,7.6-4.8c2.4-2.5,7.5-3.7,8.9-4.6
          c5.9-0.9,6.2-3.3,8.6-4.7c3.8-1.7,4.8-5.4,8.8-4.9c2.7-0.7,5.1-2.1,9-3.8C220.6,385.3,223.4,386.8,226.2,383.4z"
          />
          <path
            className="not-found-st1"
            d="M132.1,334.9c-0.2-2.2-4.5-5.2-8.6-3.8c-1.5,0.4-5.6,2-7.9,2.8c-2.3,0.8-5.9,3.4-8.3,3.1
          c-2.6,0.8-5.6,1.6-8.1,2.8c-3.2,1.2-6.7,1.6-8.1,3.2c-2.6,0.6-6.1,2.9-8.3,2.9c-2.3,2.1-5,1.8-7.9,3.1c-2.6,0.7-2.7,0.4-2,1.8
          c0.2,0.2,0.8,2.8,2.1,1.8c4.1,3.6,8.3,0.8,11.3-0.8c4.9-1,7.4-2.7,10.6-3.5c3.9,0.3,7.4-2.7,11.1-3.8c3.1-1.2,8.2-3.7,10.8-3.8
          c3-0.9,6.5-3.2,10.5-4.4C129.7,335.7,131.8,335.9,132.1,334.9z"
          />
          <path
            className="not-found-st1"
            d="M405.1,92.7C405.2,92.7,405,93,405.1,92.7c1,0.4,2.3,0.4,3.5,0.4c3,0.4,4.5-1.9,5.5-3.8
          c0.2-0.2,0.7-0.5,0.5-1.3c-0.3-0.2-0.2-1-0.9-1.2c-1-1.4-1.8-2-3.3-2.6c-2.8-0.9-5.4-0.9-7.7,1.2c-0.8,0.7-1,0.9-1.2,1.7
          c1.3,1.3,1.6,3.2,2.8,4.3C404.4,91.6,404.6,92.5,405.1,92.7z"
          />
          <path
            className="not-found-st1"
            d="M403.9,106.7c-0.6-3.3-4.2-1.9-5.6-2.7c-1.5-0.5-2.6,0.8-2.5,2.2c0.5,2.7,2.9,2.5,6,2.6
          C402.4,108.7,403.9,108.5,403.9,106.7z"
          />
          <path
            className="not-found-st1"
            d="M403.4,111.3c-0.4-0.4-0.1-0.3-0.9,0.2c-0.2,0-0.7,1-1.1,1.2c-0.1,0.2-0.5,0.6,0,0.9c0.2,1.3,1.3,2.1,2.8,1.7
          c3-1.3,7.1-0.6,11.1-4.2C410.7,110.5,406.8,110.9,403.4,111.3z"
          />
        </g>
      </g>
    </svg>
  )
}

NotFoundIcon.propTypes = {
  className: string
}

NotFoundIcon.defaultProps = {
  className: ''
}

export default NotFoundIcon
