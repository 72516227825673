export const i = [
  ['i0', '•'],
  ['i1', '•'],
  ['i2', '•'],
  ['i3', '•'],
  ['i4', '•'],
  ['i5', '•'],
  ['i6', '•'],
  ['i7', '•'],
  ['i8', '•'],
  ['i9', '•'],
  ['i10', '•']
]

export const j = [
  ['j0', '•'],
  ['j1', '•'],
  ['j2', '•'],
  ['j3', '•'],
  ['j4', '•'],
  ['j5', '•'],
  ['j6', '•'],
  ['j7', '•'],
  ['j8', '•'],
  ['j9', '•'],
  ['j10', '•'],
  ['j11', '•']
]

export const k = [
  ['k0', '.'],
  ['k1', '.'],
  ['k2', '.'],
  ['k3', '.'],
  ['k4', '.'],
  ['k5', '.'],
  ['k6', '.'],
  ['k7', '.'],
  ['k8', '.'],
  ['k9', '.'],
  ['k10', '.'],
  ['k11', '.'],
  ['k12', '.'],
  ['k13', '.'],
  ['k14', '.'],
  ['k15', '.']
]
